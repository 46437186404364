import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['progressZone']

  loadFiles(event) {
    const { detail } = event
    const { id, file } = detail
    this.progressZoneTarget.insertAdjacentHTML("beforeend", this.fileHTML(id))
    this.progressZoneTarget.lastElementChild
      .querySelector('.direct-upload__filename').textContent = file.name
  }

  loadFileProgress({ detail }) {
    const { id, progress } = detail
    const progressBar = document.getElementById(`direct-upload-progress-${id}`)
    const wholeNumberProgress = Math.floor(progress)
    progressBar.style.width = `${wholeNumberProgress}%`
    progressBar.textContent = `${wholeNumberProgress}%`
  }

  clearProgressZone() {
    this.progressZoneTarget.innerHTML = ""
  }

  markFileWithError(event) {
    event.preventDefault() // Don't alert
    const element = this.directUploadElement(id)
    element.classList.add('border border-danger border-3')
  }

  fileHTML(id) {
    return `
    <div id="direct-upload-${id}">
      <div class="progress">
        <div id="direct-upload-progress-${id}"
          class="progress-bar bg-success"
          role="progressbar"
          style="width: 0%"
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100">
        </div>
      </div>
      <span class="direct-upload__filename"></span>
    </div>`
  }
}